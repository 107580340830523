import React from "react";
import SEO from "components/seo";
import { ContactForm, ContentPhoto, FullPhoto, Header, Hero, Layout, Navigation, TextBox, WhatWeDid, } from "components/CaseLayout";
import { BEKSINSKI } from "shared/Types/cases";
import { useCaseData } from "shared/Hooks/CasesNew/useCaseData";

const Beksinski = () => {
  const data = useCaseData(BEKSINSKI);

  return (
    <Layout color={data.color}>
      <SEO title={data.title} description={data.seo} image={data.opengraph}/>
      <Hero color="#fff" data={data.hero}/>
      <Navigation type={BEKSINSKI} color="#191919"/>
      <WhatWeDid color={data.color} data={data.what_we_did}/>
      <ContentPhoto color={data.color} data={data.content_images.first}/>
      <TextBox background={data.color} color="#fff" data={data.text_boxes.first}/>
      <ContentPhoto color={data.color} data={data.content_images.second}/>
      <ContentPhoto color={data.color} data={data.content_images.third}/>
      {/* <ContentPhoto color={data.color} data={data.content_images.fourth} /> */}
      <TextBox background={data.color} color="#fff" data={data.text_boxes.second}/>
      <Header color={data.color} data={data.headers.first}/>
      <FullPhoto data={data.full_images.first}/>
      <TextBox background={data.color} color="#fff" data={data.text_boxes.third}/>
      <Header color={data.color} data={data.headers.second}/>
      <ContentPhoto color={data.color} data={data.content_images.fifth}/>
      <TextBox background={data.color} color="#fff" data={data.text_boxes.fourth}/>
      <ContentPhoto color={data.color} data={data.content_images.sixth}/>
      <TextBox background={data.color} color="#fff" data={data.text_boxes.fifth}/>
      <ContentPhoto color={data.color} data={data.content_images.seventh}/>
      <TextBox background={data.color} color="#fff" data={data.text_boxes.sixth}/>
      <ContentPhoto color={data.color} data={data.content_images.eighth}/>
      <ContentPhoto color={data.color} data={data.content_images.ninth}/>
      <FullPhoto data={data.full_images.second}/>
      <FullPhoto data={data.full_images.third}/>
      <ContactForm/>
    </Layout>
  );
};

export default Beksinski;
